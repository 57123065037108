<template>
    <div class="content">
		<div class="set">
			<div class="flex" style="justify-content: space-between;align-items:center;margin-bottom:20px">
				<h4 class="global_head_title">标签设置</h4>
				<div>
					<a-button type="primary" @click="tagShow">新建标签组</a-button>
				</div>
			</div>
			<a-list :data-source="tagList" borderd>
				<a-list-item slot="renderItem" slot-scope="item,index" style="flex:1;align-items: start;">
					<label for="" style="width:80px;margin-right:20px;">{{item.name}}</label>
					<div class="flex" style="flex:1;flex-wrap: wrap;">
						<template v-for="(tag2) in item.tag">
							<a-tag :key="tag2.value">{{tag2.value}}</a-tag>
						</template>
					</div>
					<div style="width: 130px;">
						<a class="action" href="javascript:;" @click="editTag(item.id,index)">编辑</a>
						<a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(item.id)">
							<a class="action del" href="javascript:;">删除</a>
						</a-popconfirm>
					</div>
				
				</a-list-item>
			</a-list>
			<a-modal v-model="tagVisible" :title="tagTitle" @ok="tagOk">
				<div class="content_row flex">
					<label class="info" for="">标签组名称</label>
					<div class="content_right">
						<a-input v-model="tagName" size="large" :maxLength="15" placeholder="请输入标签组名称"/>
					</div>
				</div>
				
				<div class="content_row flex" style="">
					<label class="info" for="">标签</label>
					<!-- <div class="set_" > -->
						<div class="reasonBg">
							<draggable v-model="tags" animation="1000" handle=".move" draggable=".set_con">	
								<div class="set_con" v-for="(item,index) in tags" :key="index">
									<input type="text" placeholder="" maxLength="15" v-model="item.value">
									<img src="@/assets/imgs/del.png" alt="删除" @click="del(index)" v-if="tags.length != 1">
									<img class="move" src="@/assets/imgs/move.png" alt="移动"  @start="onStart" @end="onEnd" >
								</div>
							</draggable>
							<div><a-button class="add" type="link" @click="add"><img src="@/assets/imgs/add.png" alt="添加">添加标签</a-button></div>
						</div>
					<!-- </div> -->
				</div>
			</a-modal>
			
		</div>
    </div>
</template>
<script>
// import $ from "jquery";
import {requestXml} from '../../../assets/js/request';
import draggable from 'vuedraggable'
export default {
	components: {
		draggable,
		// Sortable
	},
    data() {
        return {
			tagList: [],
			// groupVisible: false,
			tagVisible: false,
			// groupTitle: "新建分组",
			tags: [],
			tagTitle: "",
			tagName: "",
			
			type: 1,
			tagId: 0
        };
    },
    mounted: function(){
        this.getList();
    },
    methods:{
		// 选项拖拽
		onStart() {
			this.drag = true;
		},
		onEnd() {
			this.drag = false;
		},
		// 获取字段列表
        getList(){
            requestXml("/scrm/SettingTag/list","GET",(res) => {
                console.log(res)
				this.tagList = res;
            })
        },
		// 如果是新建字段 把编辑还是新建类型置为1 字段id置空 字段类型置为第一个 名称置空
		tagShow(){
			this.tagVisible = true;
			this.type = 1;
			this.tagId = "",
			this.tagName = "";
			this.tags = [{"value": ""}];
			this.tagTitle = "新建标签组";
		},
		
		// 标签选项添加
		add(){
			var obj = {"value": ""};
			this.tags.push(obj);
		},
		// 标签选项删除
		del(index){
			this.tags.splice(index,1)
			if(index == 0){
				return false;
			}
		},
		// 标签组添加
		tagOk(){
			let itemList=[];
			for(let i in this.tagList){
				itemList.push(this.tagList[i].name);
			}
            if(!this.tagName){
                this.$message.warning('请输入标签组名称');
                return false;
            }
			if(this.type == 1){
				if(itemList.indexOf(this.tagName)!=-1){
					this.$message.warning('[ '+this.tagName+' ]标签组名称已存在');
					return false;
				}
			}
			if(this.tags.length == 0){
				this.$message.warning('请至少添加一个标签项');
                return false;
			}else{
				for(let i=0;i<this.tags.length;i++){
					if(this.tags[i].value == ""){
						this.$message.warning('标签内容不能为空');
						return false;
					}
				}
			}

			this.tagVisible = false;
			var url;
			if(this.type == 1){
				url = "/scrm/SettingTag/add";
			}else{
				url = "/scrm/SettingTag/up";
			}
			requestXml(url,"POST",(res) => {
				console.log(res)
				this.getList();
            },{ "id": this.tagId,
				"name": this.tagName,
				"tag": this.tags})
        },
		// 编辑
		editTag(id,idx){
			this.tagId = id;
			this.type = 2;
			this.tagVisible = true;
			this.tagTitle = "编辑标签组";

			this.tagName = this.tagList[idx].name;
			this.tags = this.tagList[idx].tag;
		
		},
		// 删除字段
		onDelete(id){
			requestXml("/scrm/SettingTag/del","POST",(res) => {
				console.log(res)
				this.getList();
            },{"id": id})
		}
    },
};
</script>
<style scoped>
	.ant-modal{
		width: 600px !important;
	}
	.content{
		text-align: left;
	}
	.action{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 5px;
        background: #DCEEFF;
        border: 1px solid #36A3FF;
        color: #36A3FF;
        border-radius: 5px;
    }
	
    .del{
        background: #FFDCDC;
        border-color: #FF4C4C;
        color: #FF4C4C;
    }
	.del:hover{
		color: #FF4C4C;
	}
	
	/* 添加字段 */
	.content_row{
		margin: 15px 0 30px;
		align-items: baseline;
		font-size: 16px;
	}
	.content_row .info{
		width: 105px;
		padding-right: 10px;
		text-align: right;
		text-align-last: justify;
	}

	.content_row .ant-input,.content_row .ant-select{
		width: 380px;
	} 
	.content_row .reasonBg input{
		width: 300px;
		height: 36px;
		line-height: 36px;
		margin: 0 auto;
		padding-left: 10px;
	}
	.content_row .set_con{
		text-align: center;
		width: 380px;
		height: 50px;
		line-height: 50px;
		background: #F3F3F3;
		border-radius: 2px;
		margin-bottom: 14px;
	}
	.content_row img{
		width: 22px;
		height: 22px;
		margin-left: 10px; 
	}
	.ant-tag{
		margin: 4px 8px 4px 0;
		padding: 3px 7px;
	}
</style>
